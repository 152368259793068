
import { defineComponent } from 'vue'

import { User } from '@/store'

export default defineComponent({
	name: 'LogIn',
	data() {
		return {
			username: '',
			password: '',
		}
	},
	methods: {
		submit() {
			User.logIn({ username: this.username, password: this.password })
		},

		logOut() {
			User.logOut()
		},
	},
})
