<template>
	<div class="container-fluid">
		<div class="row justify-content-center align-items-center vh-100">
			<div class="text-center" style="max-width: 350px;">
				<!-- Logo -->
				<img :src="require('@/assets/ichien-text-logo.svg')" height="40" class="mb-4" />

				<!-- Login form -->
				<form @submit.prevent="submit()">
					<div class="d-grid gap-2">
						<input v-model="username" autocomplete="username" required placeholder="Логин или e-mail" class="form-control" autofocus />
						<input v-model="password" type="password" autocomplete="current-password" required placeholder="Пароль" class="form-control" />

						<!-- Submit button -->
						<button type="submit" class="btn btn-primary mt-2">Войти</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { User } from '@/store'

export default defineComponent({
	name: 'LogIn',
	data() {
		return {
			username: '',
			password: '',
		}
	},
	methods: {
		submit() {
			User.logIn({ username: this.username, password: this.password })
		},

		logOut() {
			User.logOut()
		},
	},
})
</script>
